import "dayjs/locale/id";

import {
  Button,
  CloseButton,
  Divider,
  HStack,
  Text,
  VStack,
} from "@efishery/onefish";
import dayjs from "dayjs";
import React, { useState } from "react";
import { BottomSheet } from "src/components";
import { OBSERVATORY } from "src/constants/theme";

import { IReferral } from "../../types";
import { ReferralMembersActivityCard } from "./ReferralMembersAcitivityCard";

interface ActivityReferralProps {
  buttonText: string;
  referralUsage: IReferral["members"] | null;
}

export const ActivityReferral: React.FC<ActivityReferralProps> = ({
  referralUsage,
}) => {
  dayjs.locale("id");
  const [isOpen, setIsOpen] = useState(false);

  const Reward = () => (
    <VStack>
      <HStack
        display="flex"
        alignItems="center"
        justifyContent="space-between"
        fontSize="1.25rem"
        fontWeight="600"
        width="full"
      >
        <Text>Petani Hasil Rekomendasimu</Text>
        <CloseButton
          padding="1rem 6px"
          color={OBSERVATORY[500]}
          size="24"
          onClick={() => setIsOpen(false)}
          data-testid="btn_icon_close_referral_activity"
        />
      </HStack>
      <VStack height="500px" overflowY="auto">
        {referralUsage?.map((referral) => (
          <VStack key={referral.id}>
            <ReferralMembersActivityCard referralData={referral} />
            <Divider />
          </VStack>
        ))}
      </VStack>
      <HStack
        display="flex"
        alignItems="center"
        justifyContent="center"
        fontSize="1.25rem"
        fontWeight="600"
        width="full"
        minHeight="var(--chakra-sizes-10);"
      >
        <Button
          width="full"
          size="md"
          variant="solid"
          data-testid="btn_tutup_referral_activity"
          paddingX="94px"
          onClick={() => setIsOpen(false)}
          minHeight="var(--chakra-sizes-10);"
        >
          Tutup
        </Button>
      </HStack>
    </VStack>
  );

  return (
    <>
      {referralUsage?.length ? (
        <Button
          width="full"
          size="md"
          fontWeight="500"
          variant="outline"
          data-testid="btn_referral_activity"
          paddingX="94px"
          onClick={() => setIsOpen(true)}
        >
          Cek Selengkapnya
        </Button>
      ) : null}

      <BottomSheet isOpen={isOpen} setIsOpen={setIsOpen}>
        <Reward />
      </BottomSheet>
    </>
  );
};
